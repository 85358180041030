import React, { useContext, useState, useEffect } from "react";

import { Container, Table, Row, Col } from "reactstrap";
import { dateFormatByDayMonthYear } from "./Helper";

const DocumentListView = (props) => {
  const { item } = props;
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="tableHeight">
            <Table hover className="mytable">
              <thead>
                <tr>
                  <th className="myth">Document Id</th>
                  <th className="myth">URL</th>
                </tr>
              </thead>
              <tbody>
                {item.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.documentId}</td>
                      <td>
                        <a href={item.signedUrl} target="_blank">
                          View
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DocumentListView;
