import React, { useState, useEffect } from "react";

import { Navbar, Container, Table, Nav, Row, Col, Input } from "reactstrap";
import Loader from "../../common/Loader";
import { fetchCustomer } from "../../services/APIServices";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { dateFormatByDayMonthYear } from "../../common/Helper";
import { useAuth0 } from "@auth0/auth0-react";

const PAGE_SIZE = 10;

const Customers = (props) => {
  const { verifStatus, pageNo } =
    (props.location && props.location.state) || {};

  const [showLoader, setShowLoader] = useState(false);
  const [customers, setCustomers] = useState(null);
  const [verificationStatus, setVerificationStatus] = useState(
    verifStatus ? verifStatus : null
  );
  const [selectedPageNo, setSelectedPageNo] = useState(1);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    var payload = {
      pageNumber: pageNo ? pageNo : selectedPageNo,
      pageSize: PAGE_SIZE,
    };
    if (verifStatus) {
      payload = {
        pageNumber: pageNo,
        pageSize: PAGE_SIZE,
        filters: {
          verification_status: verifStatus,
        },
      };
    }
    fetchData(payload);
  }, []);

  const fetchData = async (payload) => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    fetchCustomer(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setCustomers(response);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onPageNumClick = async (index) => {
    if (parseInt(index) === parseInt(customers.currentPage)) {
      return;
    }
    var payload = {
      pageNumber: index,
      pageSize: PAGE_SIZE,
    };
    if (verificationStatus !== null) {
      payload = {
        pageNumber: index,
        pageSize: PAGE_SIZE,
        filters: {
          verification_status: verificationStatus,
        },
      };
    }
    setSelectedPageNo(index);
    fetchData(payload);
  };

  const onDropDownClick = (status) => {
    const payload = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      filters: {
        verification_status: status,
      },
    };
    fetchData(payload);
    setVerificationStatus(status);
  };

  const onItemClicked = (item) => {
    props.history.push({
      pathname: "/customer/details",
      state: {
        selectedCustomer: item,
        verifStatus: verificationStatus,
        pageNo: selectedPageNo,
      },
    });
  };

  return (
    <Container fluid>
      <h4>Account Holders</h4>

      <Navbar className="p-4 mb-2 mt-2" expand="md">
        <Nav className="ml-auto" fill pills>
          <div className="security_status_comment">
            <Input
              className="input-border"
              id="customer_risk"
              name="select"
              type="select"
              value={verificationStatus ? verificationStatus : "1"}
              onChange={(e) => onDropDownClick(e.target.value)}
            >
              <option value="1" disabled>
                Select Status
              </option>
              <option>PENDING</option>
              <option>KYC PENDING</option>
              <option>UNVERIFIED</option>
              <option>VERIFIED</option>
              <option>EXVERIFIED</option>
              <option>REFERRED</option>
              <option>DECLINED</option>
              <option>REVIEWED</option>
            </Input>
          </div>
        </Nav>
      </Navbar>
      {customers && customers.data && customers.data.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>There are no account holders.</h6>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 10 }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            {customers && customers.data && customers.data.length > 0 && (
              <PaginationControl
                page={customers.currentPage}
                between={3}
                total={customers.totalPages}
                limit={1}
                changePage={onPageNumClick}
                ellipsis={1}
                last={true}
              />
            )}
          </div>
          <div
            style={{
              minHeight: "200px",
              maxHeight: "600px",
              overflowY: "auto",
            }}
          >
            {customers && customers.data && customers.data.length > 0 && (
              <Container fluid>
                <Row>
                  <Col>
                    <div className="tableHeight">
                      <Table
                        striped
                        hover
                        bordered
                        className="mytable table.table-bordered"
                      >
                        <thead>
                          <tr className="table-primary">
                            <th className="myth">First Name</th>
                            <th className="myth">Last Name</th>
                            <th className="myth">Care Receiver</th>
                            <th className="myth">Verification Status</th>
                            <th className="myth">Account Status</th>
                            <th className="myth">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.data.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => onItemClicked(item)}
                                style={{ cursor: "pointer" }}
                              >
                                <td>{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>
                                  {item.beneficiary === true
                                    ? `${item.firstName} ${item.lastName}`
                                    : item.careReceiverName}
                                </td>
                                <td>
                                  {item.verificationStatus &&
                                    item.verificationStatus.toUpperCase()}
                                </td>
                                <td>{item.accountStatus}</td>
                                <td>
                                  {dateFormatByDayMonthYear(item.createdDate)}
                                </td>
                                {/* <td>
                            <Button onClick={() => onUploadClick(item)}>
                              Upload
                            </Button>
                          </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </>
      )}
      {showLoader && (
        <div style={{ zIndex: 5 }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default Customers;
