import React, { useContext, useEffect, useState } from "react";

import {
  Navbar,
  Button,
  Container,
  Table,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Form,
  FormGroup,
} from "reactstrap";
import { fetchDocumentByOwnerId } from "../services/APIServices";

import Loader from "./Loader";
import { useAuth0 } from "@auth0/auth0-react";
import DetailViewInformation from "./DetailViewInformation";
import DocumentListView from "./DocumentListView";

const DetailView = (props) => {
  const {
    selectedItem,
    pageNo,
    pageTitle,
    pageFrom,
    pStartDate,
    pEndDate,
    search,
  } = (props.location && props.location.state) || {};
  // const queryParams = new URLSearchParams(props.location.search);
  // const prospectId = queryParams.get("Id");
  const { getAccessTokenSilently } = useAuth0();

  const [showLoader, setShowLoader] = useState(false);
  const [documents, setDocuments] = useState(null);

  const [open, setOpen] = useState("1");

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    const payload = {
      ownerId: selectedItem.paymentId,
      documentType: "PaymentReceipt",
    };
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    fetchDocumentByOwnerId(payload, token)
      .then((response) => {
        if (response.responseCode === 0) {
          setDocuments(response);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Container fluid>
      <h5>{pageTitle}</h5>
      <Navbar className="p-4 mb-2 mt-2" expand="md">
        <Nav className="ml-auto" fill pills>
          <Button
            className="button-back"
            onClick={() => {
              props.history.push({
                pathname: pageFrom,
                state: {
                  pageNo: pageNo,
                  pStartDate: pStartDate,
                  pEndDate: pEndDate,
                  search: search,
                },
              });
            }}
          >
            Back
          </Button>
        </Nav>
      </Navbar>
      <Container fluid>
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <h5>Details</h5>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <Card className="my-2" color="danger" outline>
                    <CardBody>
                      <CardTitle tag="h5">
                        {pageFrom === "/transactions"
                          ? "Payer Detail"
                          : "Account Holder Detail"}
                      </CardTitle>
                      <DetailViewInformation
                        item={selectedItem}
                        pageFrom={pageFrom}
                        cardType={1}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card className="my-2" color="secondary" outline>
                    <CardBody>
                      <CardTitle tag="h5">
                        {pageFrom === "/transactions"
                          ? "Payee Detail"
                          : "Carer Detail"}
                      </CardTitle>
                      <DetailViewInformation
                        item={selectedItem}
                        pageFrom={pageFrom}
                        cardType={2}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AccordionBody>
          </AccordionItem>
          {selectedItem &&
            selectedItem.hasDocument &&
            documents &&
            documents.docs && (
              <AccordionItem>
                <AccordionHeader targetId="2">
                  <h5>Attached Documents</h5>
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  <DocumentListView item={documents.docs} />
                </AccordionBody>
              </AccordionItem>
            )}
        </Accordion>
      </Container>
      {showLoader && <Loader />}
    </Container>
  );
};

export default DetailView;
