import React, { useEffect, useState } from "react";

import {
  Navbar,
  Button,
  Container,
  Nav,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  fetchCustomerInfo,
  fetchPayeeByCustomer,
} from "../../services/APIServices";

import Loader from "../../common/Loader";
import CustomerInformation from "./CustomerInformation";
import AssociatedCarers from "./AssociatedCarers";

import { useAuth0 } from "@auth0/auth0-react";

const CustomerDetails = (props) => {
  const { selectedCustomer, verifStatus, pageNo } =
    (props.location && props.location.state) || {};
  const { getAccessTokenSilently } = useAuth0();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [securityInfo, setSecurityInfo] = useState({
    person: null,
  });
  const [showLoader, setShowLoader] = useState(false);
  const [associatedPayees, setAssociatedPayees] = useState(null);

  const [open, setOpen] = useState("1");

  useEffect(() => {
    loadCustomerInfo();
  }, []);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const getPayeeByCustomer = async () => {
    const token = await getAccessTokenSilently();
    const payload = {
      customerId: selectedCustomer.prospectId,
    };
    fetchPayeeByCustomer(payload, token)
      .then((response) => {
        setAssociatedPayees(response);
      })
      .catch((err) => {});
  };

  const loadCustomerInfo = async () => {
    const payload = {
      prospectId: selectedCustomer.prospectId,
    };
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    fetchCustomerInfo(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setSecurityInfo({
            ...securityInfo,
            person: response.person,
          });
          getPayeeByCustomer();
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <Container fluid>
      <h5>Account Holder Information</h5>
      <Navbar className="p-4 mb-2 mt-2" expand="md">
        <Nav className="ml-auto" fill pills>
          <Button
            className="button-back"
            onClick={() => {
              props.history.push({
                pathname: "/customer",
                state: { verifStatus: verifStatus, pageNo: pageNo },
              });
            }}
          >
            Back
          </Button>
        </Nav>
      </Navbar>
      <Container fluid>
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <h5>Details</h5>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <CustomerInformation
                item={selectedCustomer}
                address={
                  securityInfo.person ? securityInfo.person.addressList : null
                }
                {...props}
              />
            </AccordionBody>
          </AccordionItem>
          {associatedPayees && associatedPayees.payees && (
            <AccordionItem>
              <AccordionHeader targetId="2">
                <h5>Carers</h5>
              </AccordionHeader>
              <AccordionBody accordionId="2">
                {associatedPayees && associatedPayees.payees && (
                  <AssociatedCarers item={associatedPayees.payees} {...props} />
                )}
              </AccordionBody>
            </AccordionItem>
          )}
        </Accordion>
      </Container>
      {showLoader && <Loader />}
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default CustomerDetails;
