import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap";

import { maskExceptLast4 } from "../../common/Helper";

const PartnerIntegrationDetails = (porps) => {
  const { header, partnerDetail, type, onGenerateKeys, onResetAPISecretKeys } =
    porps;
  const [showSandboxAPIKey, setShowSandboxAPIKey] = useState(false);
  const [showSandboxSecretKey, setShowSandboxSecretKey] = useState(false);

  const [showProductionAPIKey, setShowProductionAPIKey] = useState(false);
  const [showProductionSecretKey, setShowProductionSecretKey] = useState(false);


  const onClickGenerate = () => {
    if (onGenerateKeys) {
      onGenerateKeys(type);
    }
  };

  const onCopyClick = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const onResetClick = () => {
    if (onResetAPISecretKeys) {
      onResetAPISecretKeys(type);
    }
  };

  const onShowSandboxAPIKeyClick = () => {
    setShowSandboxAPIKey(!showSandboxAPIKey);
  };
  const onShowSandboxSerectKeyClick = () => {
    setShowSandboxSecretKey(!showSandboxSecretKey);
  };

  const onShowProductionAPIKeyClick = () => {
    setShowProductionAPIKey(!showProductionAPIKey);
  };
  const onShowProductionSerectKeyClick = () => {
    setShowProductionSecretKey(!showProductionSecretKey);
  };

  return (
    <Card className="my-2" color="secondary" outline style={{}}>
      <CardHeader>{header}</CardHeader>
      <CardBody>
        {!partnerDetail.integration.sandboxAPIKey && type === "sandbox" && (
          <>
            <CardTitle tag="h5">
              {"Generate sandbox API integration and secret key"}
            </CardTitle>
            <Button onClick={onClickGenerate}>Generate</Button>
          </>
        )}
        {partnerDetail.integration.sandboxAPIKey && type === "sandbox" && (
          <div>
            <div className="partner-integration-info">
              API Key:{" "}
              {showSandboxAPIKey
                ? partnerDetail.integration.sandboxAPIKey
                : maskExceptLast4(partnerDetail.integration.sandboxAPIKey)}{" "}
              <Button
                className={showSandboxAPIKey ? 'icon-hide' : "icon-show"}
                onClick={onShowSandboxAPIKeyClick}
              />{" "}
              <Button
                className="icon-copy"
                onClick={() =>
                  onCopyClick(partnerDetail.integration.sandboxAPIKey)
                }
              />
            </div>
            <div className="partner-integration-info">
              Secret Key:{" "}
              {showSandboxSecretKey
                ? partnerDetail.integration.sandboxSecretKey
                : maskExceptLast4(
                    partnerDetail.integration.sandboxSecretKey
                  )}{" "}
              <Button
                className={showSandboxSecretKey ? 'icon-hide' : "icon-show"}
                onClick={onShowSandboxSerectKeyClick}
              />{" "}
              <Button
                className="icon-copy"
                onClick={() =>
                  onCopyClick(partnerDetail.integration.sandboxSecretKey)
                }
              />{" "}
              <Button className="icon-reset" onClick={onResetClick} />
            </div>
          </div>
        )}
        {!partnerDetail.integration.productionAPIKey &&
          type === "production" && (
            <>
              <CardTitle tag="h5">
                {"Generate production API integration and secret key"}
              </CardTitle>
              <Button onClick={onClickGenerate}>Generate</Button>
            </>
          )}
        {partnerDetail.integration.productionAPIKey &&
          type === "production" && (
            <div>
              <div className="partner-integration-info">
                API Key:{" "}
                {showProductionAPIKey
                  ? partnerDetail.integration.productionAPIKey
                  : maskExceptLast4(
                      partnerDetail.integration.productionAPIKey
                    )}{" "}
                <Button
                  className={showProductionAPIKey ? 'icon-hide' : "icon-show"}
                  onClick={onShowProductionAPIKeyClick}
                />{" "}
                <Button
                  className="icon-copy"
                  onClick={() =>
                    onCopyClick(partnerDetail.integration.productionAPIKey)
                  }
                />
              </div>
              <div className="partner-integration-info">
                Secret Key:{" "}
                {showProductionSecretKey
                  ? partnerDetail.integration.productionSecretKey
                  : maskExceptLast4(
                      partnerDetail.integration.productionSecretKey
                    )}{" "}
                <Button
                  className={showProductionSecretKey ? 'icon-hide' : "icon-show"}
                  onClick={onShowProductionSerectKeyClick}
                />{" "}
                <Button
                  className="icon-copy"
                  onClick={() =>
                    onCopyClick(partnerDetail.integration.productionSecretKey)
                  }
                />{" "}
                <Button className="icon-reset" onClick={onResetClick} />
              </div>
            </div>
          )}
        {type === "applinks" && (
          <>
            <div className="partner-integration-info">
              iOS: {partnerDetail.integration.iosappLink}{" "}
              <Button
                className="icon-copy"
                onClick={() =>
                  onCopyClick(partnerDetail.integration.iosappLink)
                }
              />
            </div>
            <div className="partner-integration-info">
              Android: {partnerDetail.integration.androidAppLink}{" "}
              <Button
                className="icon-copy"
                onClick={() =>
                  onCopyClick(partnerDetail.integration.androidAppLink)
                }
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PartnerIntegrationDetails;
