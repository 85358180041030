import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { savePartnerBankDetails } from "../../services/APIServices";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../common/Loader";

const PartnerBankDetailsForm = (props) => {
  const { title, show, onShowPopup, details, onDetailsUpdate } = props;
  const [modal, setModal] = useState(show);
  const [showLoader, setShowLoader] = useState(false);
  const [bankDetail, setBankDetail] = useState({
    accountName: details.account.accountName
      ? details.account.accountName
      : undefined,
    sortCode: details.account.sortCode ? details.account.sortCode : undefined,
    accountNo: details.account.accountNo
      ? details.account.accountNo
      : undefined,
  });

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {}, []);

  const toggle = () => {
    setModal(!modal);
    onShowPopup(!modal);
  };

  const createOrUpdateBankDetailWithData = async (payload) => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    savePartnerBankDetails(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          toggle();
          if (onDetailsUpdate) {
            onDetailsUpdate(response.details);
          }
        } else {
          alert(response.responseMessage);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onClickSubmit = () => {
    if (!validateFormData()) {
      return;
    }
    const payload = {
      account: {
        accountName: bankDetail.accountName,
        sortCode: bankDetail.sortCode,
        accountNo: bankDetail.accountNo,
      },
    };

    createOrUpdateBankDetailWithData(payload);
  };

  const validateFormData = () => {
    if (!bankDetail.accountName) {
      alert("Please enter the name");
      return false;
    } else if (!bankDetail.sortCode) {
      alert("Please enter the email id");
      return false;
    } else if (!bankDetail.accountNo) {
      alert("Please enter the business name");
      return false;
    } else {
      return true;
    }
  };

  const onNameEnter = (value) => {
    setBankDetail({ ...bankDetail, accountName: value });
  };

  const onSortCodeEnter = (value) => {
    setBankDetail({ ...bankDetail, sortCode: value });
  };

  const onAccountNoEnter = (value) => {
    setBankDetail({ ...bankDetail, accountNo: value });
  };

  return (
    <Modal
      isOpen={modal}
      fade={true}
      toggle={toggle}
      centered={true}
      backdrop={false}
      size="lg"
    >
      <ModalHeader className="modal-header-bg-color" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="name" className="h6">
                  Account Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="input"
                  value={bankDetail.accountName}
                  placeholder="Type here"
                  onChange={(e) => onNameEnter(e.target.value)}
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="sortcode" className="h6">
                  Sort Code
                </Label>
                <Input
                  id="sortcode"
                  name="sortcode"
                  placeholder="Type here"
                  value={bankDetail.sortCode}
                  onChange={(e) => onSortCodeEnter(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="accountno" className="h6">
                  Account Number
                </Label>
                <Input
                  id="accountno"
                  name="accountno"
                  placeholder="Type here"
                  value={bankDetail.accountNo}
                  onChange={(e) => onAccountNoEnter(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter className="modal-header-bg-color">
        <Button className="button-back" onClick={onClickSubmit}>
          {title}
        </Button>
      </ModalFooter>
      {showLoader && <Loader />}
    </Modal>
  );
};

export default PartnerBankDetailsForm;
