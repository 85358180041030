import React, { useState } from "react";

import {
  Container,
  Label,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Button,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";
import { ISODateFormatter } from "../../common/Helper";
import { downloadReport } from "../../services/APIServices";
import Loader from "../../common/Loader";
import { useAuth0 } from "@auth0/auth0-react";

import {
  onSendOTPLinkAccount,
  onSendOTPAddPayee,
  onSendOTPPaymentRequst,
  onLinkAccount,
  onAddPayee,
  onVerifyPayeeName,
  onStartPaymentRequest,
  onSendPaymentRequest,
  onUploadDocument,
  onAddPayeeWithOtp,
  onSendPaymentRequestWithOtp,
  onTestAPI,
} from "../../__tests__/APITest";

const Reports = (props) => {
  const startDate = new Date();
  startDate.setUTCDate(startDate.getUTCDate() - 90);
  const [startDateValue, setStartDateValue] = useState(startDate.toISOString());
  const [endDateValue, setEndDateValue] = useState(new Date().toISOString());
  const [selectedReportType, setSelectedReportType] = useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const [showLoader, setShowLoader] = useState(false);

  const [testAPIs] = useState(false);

  const handleChangeStartDate = (value, formattedValue) => {
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate() - 90);
      setStartDateValue(date.toISOString());
    } else {
      setStartDateValue(value);
    }
  };

  const handleChangeEndDate = (value, formattedValue) => {
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate());
      setEndDateValue(date.toISOString());
    } else {
      setEndDateValue(value);
    }
  };

  const onReportTypeSelected = (type) => {
    setSelectedReportType(type);
  };

  const onDownloadClick = async () => {
    const payload = {
      reportType: selectedReportType === 1 ? "paymentrequest" : "transaction",
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    const token = await getAccessTokenSilently();

    setShowLoader(true);
    downloadReport(payload, token)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        const fileName =
          selectedReportType === 1
            ? "PaymentRequest_Report_" +
              ISODateFormatter(startDateValue) +
              "To" +
              ISODateFormatter(endDateValue) +
              ".csv"
            : "Transaction_Report_" +
              ISODateFormatter(startDateValue) +
              "To" +
              ISODateFormatter(endDateValue) +
              ".csv";
        link.setAttribute("download", fileName); // Specify the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  return (
    <Container fluid>
      <h4>Reports</h4>
      <Row style={{ marginTop: 0, marginLeft: 0 }}>
        <Col>
          <Card className="my-2 card-report-container">
            <CardBody>
              <Form>
                <FormGroup tag="fieldset">
                  <FormGroup>
                    <h6>Select Report type</h6>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="radio1"
                      id="1"
                      type="radio"
                      value={selectedReportType}
                      checked={selectedReportType === 1 ? true : false}
                      onClick={() => onReportTypeSelected(1)}
                      onChange={() => {}}
                    />
                    <Label check>Payment Requests Report</Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      name="radio1"
                      id="2"
                      type="radio"
                      value={selectedReportType}
                      checked={selectedReportType === 2 ? true : false}
                      onClick={() => onReportTypeSelected(2)}
                      onChange={() => {}}
                    />
                    <Label check>Transactions Report</Label>
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <FormGroup>
                    <h6>Select Report Date Range</h6>
                  </FormGroup>
                  <FormGroup>
                    <FormText>
                      The date range can be maximum of 3 months
                    </FormText>
                    <FormGroup>
                      <div className="datepicker-container-report">
                        <div className="datepicker">
                          <Label className="nowrap">Start Date</Label>
                          <DatePicker
                            id="start-datepicker"
                            value={startDateValue}
                            onChange={(v, f) => handleChangeStartDate(v, f)}
                          />
                        </div>
                        <div className="datepicker">
                          <Label className="nowrap">End Date</Label>
                          <DatePicker
                            id="end-datepicker"
                            value={endDateValue}
                            onChange={(v, f) => handleChangeEndDate(v, f)}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </FormGroup>
                </FormGroup>
                <div className="btn-report-download">
                  <Button className="button-back" onClick={onDownloadClick}>
                    Download
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {testAPIs && (
        <Row style={{ marginTop: 10, marginLeft: 10 }}>
          <Col>
            <Card
              className="my-2"
              style={{
                width: "60%",
              }}
            >
              <CardBody>
                <Row>
                  {/* <Col>
                    <div className="btn-report-download">
                      <Button onClick={() => onTestAPI()}>Test API</Button>
                    </div>
                  </Col> */}
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={() => onSendOTPLinkAccount()}>
                        Send OTP LC
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onLinkAccount}>Link Customer</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onVerifyPayeeName}>
                        Verify Bens Name
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onAddPayee}>Add Bens</Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onSendOTPAddPayee}>Send OTP AB</Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onAddPayeeWithOtp}>
                        Add Bens with Otp
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onStartPaymentRequest}>Start PR</Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onSendPaymentRequest}>Send PR</Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onSendOTPPaymentRequst}>
                        Send OTP PR
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onSendPaymentRequestWithOtp}>
                        Send PR with Otp
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-report-download">
                      <Button onClick={onUploadDocument}>Upload Doc</Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      {showLoader && (
        <div style={{ zIndex: 5 }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default Reports;
