import React, { useState, useEffect } from "react";

import {
  Navbar,
  Button,
  Container,
  Table,
  Nav,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import Loader from "../../common/Loader";
import { fetchTransaction } from "../../services/APIServices";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { DatePicker } from "reactstrap-date-picker";
import { ISODateFormatter } from "../../common/Helper";
import {
  dateFormatByDayMonthYear,
  currencyFormatter,
} from "../../common/Helper";
import { useAuth0 } from "@auth0/auth0-react";

const PAGE_SIZE = 10;

const Transactions = (props) => {
  const { pageNo, pStartDate, pEndDate, search } =
    (props.location && props.location.state) || {};
  const [showLoader, setShowLoader] = useState(false);
  const [transactions, setTransactions] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const [selectedPageNo, setSelectedPageNo] = useState(pageNo ? pageNo : 1);

  const startDate = new Date();
  startDate.setUTCDate(startDate.getUTCDate() - 90);
  const [startDateValue, setStartDateValue] = useState(
    pStartDate ? pStartDate : startDate.toISOString()
  );

  const endDate = new Date();
  endDate.setUTCDate(endDate.getUTCDate());
  const [endDateValue, setEndDateValue] = useState(
    pEndDate ? pEndDate : endDate.toISOString()
  );

  const [searchText, setSearchText] = useState(search ? search : undefined);

  const handleChangeStartDate = (value, formattedValue) => {
    setSelectedPageNo(1);
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate() - 90);
      setStartDateValue(date.toISOString());
    } else {
      setStartDateValue(value);
    }
  };

  const handleChangeEndDate = (value, formattedValue) => {
    setSelectedPageNo(1);
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate());
      setEndDateValue(date.toISOString());
    } else {
      setEndDateValue(value);
    }
  };

  useEffect(() => {
    var payload = {
      pageNumber: selectedPageNo,
      pageSize: PAGE_SIZE,
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    if (searchText) {
      payload = {
        pageNumber: selectedPageNo,
        pageSize: PAGE_SIZE,
        startDate: ISODateFormatter(startDateValue),
        endDate: ISODateFormatter(endDateValue),
        filters: {
          accountNo: searchText,
        },
      };
    }
    fetchData(payload);
  }, [startDateValue, endDateValue]);

  const fetchData = async (payload) => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    fetchTransaction(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setTransactions(response);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onPageNumClick = async (index) => {
    if (parseInt(index) === parseInt(transactions.currentPage)) {
      return;
    }
    var payload = {
      pageNumber: index,
      pageSize: PAGE_SIZE,
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    if (searchText) {
      payload = {
        pageNumber: index,
        pageSize: PAGE_SIZE,
        startDate: ISODateFormatter(startDateValue),
        endDate: ISODateFormatter(endDateValue),
        filters: {
          accountNo: searchText,
        },
      };
    }
    setSelectedPageNo(index);
    fetchData(payload);
  };

  const onItemClick = (item) => {
    props.history.push({
      pathname: "/transactions/details",
      state: {
        selectedItem: item,
        pageNo: selectedPageNo,
        pageTitle: "Transaction Detail",
        pageFrom: "/transactions",
        pStartDate: startDateValue,
        pEndDate: endDateValue,
        search: searchText,
      },
    });
  };

  const searchTextEnter = (value) => {
    setSearchText(value);
    if (value.length === 0) {
      setSelectedPageNo(1);
      const payload = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        startDate: ISODateFormatter(startDateValue),
        endDate: ISODateFormatter(endDateValue),
      };
      fetchData(payload);
    }
  };

  const onSearchClick = () => {
    setSelectedPageNo(1);
    var payload = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
      filters: {
        accountNo: searchText,
      },
    };
    if (!searchText) {
      payload = {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        startDate: ISODateFormatter(startDateValue),
        endDate: ISODateFormatter(endDateValue),
      };
    }
    fetchData(payload);
  };

  return (
    <Container fluid>
      <h4> Transactions</h4>
      <Navbar className="p-4 mb-2 mt-2" expand="md">
        <Nav className="ml-auto" fill pills>
          <div className="datepicker-container">
            <div className="datepicker">
              <Label className="nowrap">Start Date</Label>
              <DatePicker
                id="start-datepicker"
                value={startDateValue}
                onChange={(v, f) => handleChangeStartDate(v, f)}
              />
            </div>
            <div className="datepicker">
              <Label className="nowrap">End Date</Label>
              <DatePicker
                id="end-datepicker"
                value={endDateValue}
                onChange={(v, f) => handleChangeEndDate(v, f)}
              />
            </div>
            <div className="search-container">
              <Input
                placeholder="Account No."
                style={{ width: 200 }}
                value={searchText}
                onChange={(e) => searchTextEnter(e.target.value)}
              />
              <Button className="button-back" onClick={onSearchClick}>
                Search
              </Button>
            </div>
          </div>
        </Nav>
      </Navbar>
      {transactions && transactions.data && transactions.data.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>There are no transactions.</h6>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 30 }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            {transactions &&
              transactions.data &&
              transactions.data.length > 0 && (
                <PaginationControl
                  page={transactions.currentPage}
                  between={3}
                  total={transactions.totalPages}
                  limit={1}
                  changePage={onPageNumClick}
                  ellipsis={1}
                  last={true}
                />
              )}
          </div>
          <div
            style={{
              minHeight: "200px",
              maxHeight: "600px",
              overflowY: "auto",
            }}
          >
            {transactions &&
              transactions.data &&
              transactions.data.length > 0 && (
                <Container fluid>
                  <Row>
                    <Col>
                      <div className="tableHeight">
                        <Table
                          striped
                          hover
                          bordered
                          className="mytable table.table-bordered"
                        >
                          <thead>
                            <tr className="table-primary">
                              <th className="myth">Id</th>
                              <th className="myth">Payer</th>
                              <th className="myth">Payee</th>
                              <th className="myth">Payment Type</th>
                              <th className="myth">Amount</th>
                              <th className="myth">Description</th>
                              {/* <th className="myth">Flagged Transaction</th> */}
                              <th className="myth">Transaction Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.data.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  onClick={() => onItemClick(item)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{item.transactionId}</td>
                                  <td>
                                    {item.payerName
                                      ? item.payerName
                                      : item.firstName + " " + item.lastName}
                                  </td>
                                  <td>
                                    {item.isCredit
                                      ? item.firstName + " " + item.lastName
                                      : item.payee}
                                  </td>
                                  <td>
                                    {item.isCredit ? "Inbound" : "Outbound"}
                                  </td>
                                  <td>{currencyFormatter(item.amount)}</td>
                                  <td>{item.description}</td>
                                  {/* <td>{item.ruleType ? "Yes" : "No"}</td> */}
                                  <td>
                                    {dateFormatByDayMonthYear(
                                      item.transactionDate
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
          </div>
        </>
      )}
      {showLoader && (
        <div style={{ zIndex: 5 }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default Transactions;
