import React from "react";
import { Form, FormGroup, Row, Col, Label } from "reactstrap";

const PartnerDetailsInfo = (props) => {
  const { partnerDetail } = props;
  return (
    <Form>
      <Row>
        <Col>
          <FormGroup>
            <Label for="name" className="h6">
              {'Name:'}
            </Label>
            <Label for="namevalue" className="h6 partner-detail-into">
              {partnerDetail.name}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="emailid" className="h6">
              Email Id:
            </Label>
            <Label for="emailidvalue" className="h6 partner-detail-into">
              {partnerDetail.email}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="businessname" className="h6">
              Business Name:
            </Label>
            <Label for="businessnamevalue" className="h6 partner-detail-into">
              {partnerDetail.businessName}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="phone" className="h6">
              Phone Number:
            </Label>
            <Label for="phonevalue" className="h6 partner-detail-into">
              {partnerDetail.phoneNo}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="address" className="h6">
              Address:
            </Label>
            <Label for="addressvalue" className="h6 partner-detail-into"></Label>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnerDetailsInfo;
