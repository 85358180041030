import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Nav, Button } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


import logo from "../../assets/logo-no-background.png";
import overview from "../../assets/overview.png";
import account_holder from "../../assets/account-holder.png";
import carer from "../../assets/carer.png";
import payment_request from "../../assets/payment.png";
import transaction from "../../assets/transaction.png";
import report from "../../assets/report.png";
import configuration from "../../assets/configuration.png";
import right_arrow from "../../assets/arrow-right.png";
import close from "../../assets/close.png";
import signout from "../../assets/signout.png";



const menuData = [
  {
    name: "Overview",
    link: "/overview",
    icon: overview,
  },
  {
    name: "Account Holders",
    link: "/customer",
    icon: account_holder,
  },
  {
    name: "Carers",
    link: "/carers",
    icon: carer,
  },
  {
    name: "Payment Requests",
    link: "/payment-requests",
    icon: payment_request,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: transaction,
  },
  {
    name: "Reports",
    link: "/reports",
    icon: report,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: configuration,
  },
];

const SideBarMobile = (props) => {

  const { isOpen, onBurgerMenuClose } = props;
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { href } = window.location;

  const onLinkClick = (item) => {
    setSelectedMenu(item.name);
    onBurgerMenuClose();
  };

  const getMenuByHrefLocation = () => {
    if (href) {
      const lastItem = href.substring(href.lastIndexOf("/"));
      if (lastItem === "/") {
        return menuData[0].name;
      } else {
        const result = menuData.filter((item) => item.link === lastItem);
        if (result.length > 0) {
          return result[0].name;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    const pageName = getMenuByHrefLocation();
    if (pageName) {
      setSelectedMenu(pageName);
    }
  }, [selectedMenu]);

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Nav className={classNames("nav-container sidebar", { "is-open": isOpen })} style={{ padding: 0 }}>
      {/* <div className="os-host os-host-foreign os-host-resize-disabled css-17bgnci os-host-scrollbar-horizontal-hidden os-host-overflow os-host-overflow-y os-host-transition"> */}
      <div className="os-resize-observer-host observed">
        <div className="os-resize-observer" style={{ left: 0, right: "auto" }}></div>
        {/* ::after */}
      </div>
      <div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: "left" }}>
        <div className="os-resize-observer"></div>
      </div>
      <div className="os-content-glue" style={{ margin: -20 }}></div>
      <div className="os-padding">
        <div className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid"
          style={{ overflowY: "scroll" }}>
          <div className="os-content" style={{ padding: 20, height: "100%", width: "100%" }}>
            {/* ::before */}
            <div className="css-1wpdhyu">
              <div className="sidebar-header css-axrk4p">
                <div className="css-1lsh4kf">
                  <img
                    className="css-logo"
                    src={logo}
                    alt="HealthMoni"
                    width={160}
                    height={64}
                  />
                </div>
                <img
                  className="css-logo"
                  src={close}
                  alt="alt-close"
                  width={30}
                  height={30}
                  onClick={onBurgerMenuClose}
                />
              </div>
              <div tabIndex={0} id="explorer-menu" className="css-sidemenu">
                <div style={{ display: "block" }}>
                  <div className="css-sideitems">
                    <div className="css-sideitem">
                      <div className="side-menu">
                        {/* <Nav vertical className="list-unstyled pb-3"> */}
                        <div>
                          {menuData.map((item, index) => {
                            return (
                              <NavItem
                                key={index}
                                className={item.name === selectedMenu ? "active" : ""}
                                onClick={() => {
                                  onLinkClick(item);
                                }}
                              >
                                <div className="mymenu-item">
                                  <NavLink tag={Link} to={item.link} className="mymenu-item">
                                    <div className="side-menu-item">
                                      <img className="m-2 app-logo"
                                        src={item.icon}
                                        alt={"img_" + index}
                                        width={32}
                                        height={32}
                                      />
                                      <div className="text-dark fs-6 h4 arrow-text">
                                        {item.name}
                                      </div>
                                    </div>
                                    <div className="img-arrow-container">
                                      <img className="m-2 img-arrow"
                                        src={right_arrow}
                                        alt={"img_arrow" + index}
                                        width={20}
                                        height={20}
                                      />
                                    </div>
                                  </NavLink>
                                </div>
                              </NavItem>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="css-1lsh4kf" style={{ height: 75, justifyContent: "flex-end"}}  onClick={logoutWithRedirect}>
                {isAuthenticated && (
                  // <Button className="button" onClick={logoutWithRedirect}>
                  //   Sign Out
                  // </Button>
                  <div>
                    <img src={signout}
                      alt='signout'
                      className="mobile-signout"
                      />
                    Sign out
                  </div>
                )}
              </div>
            </div>
            {/* ::after */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </Nav>
  );
};
export default SideBarMobile;
