import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalPopup = (props) => {
  const { title, message, show, onShowPopup, onPopupYes } = props;
  const [modal, setModal] = useState(show);

  const toggle = () => {
    setModal(!modal);
    onShowPopup(!modal);
  };

  const onClickYes = () => {
    if(onPopupYes){
      setModal(!modal);
      onPopupYes(!modal)
    }
  }

  return (
    <Modal
      isOpen={modal}
      fade={true}
      toggle={toggle}
      centered={true}
      backdrop={false}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          No
        </Button>
        <Button color="secondary" onClick={onClickYes}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPopup;
