import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route } from "react-router-dom";

import Topbar from "./Topbar";
import Bottombar from "./Bottombar";
import Dashboard from "../dashboard/Dashboard";
import Customers from "../customer/Customers";
import CustomerDetails from "../customer/CustomerDetails";
import Carers from "../carer/Carers";
import PaymentRequests from "../paymentrequests/PaymentRequests";
import Transactions from "../transactions/Transactions";
import Reports from "../reports/Reports";
import DetailView from "../../common/DetailView";
import SystemConfiguration from "../systemconfiguration/SystemConfiguration";
import SideBarMobile from "../sidebar/SideBarMobile";

const Content = ({ sidebarIsOpen, toggleSidebar }) => {
  const [showMobileMenu, setShowmobileMenu] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onBurgerMenuClick = (state) => {
    setShowmobileMenu(state)
  }

  const onBurgerMenuClose = () => {
    setShowmobileMenu(false);
  }

  if (showMobileMenu && width <= 768){
    return <SideBarMobile onBurgerMenuClose={onBurgerMenuClose}/>
  }

  if(showMobileMenu && width > 768){
    setShowmobileMenu(false);
  }

  return (<div className="css-main">
    {/* <div className="main-box"> */}
    <Container fluid className="p-0">
      <Container
        fluid
        className={classNames("content p-0", { "is-open": sidebarIsOpen })}
      >
        
        <Topbar toggleSidebar={toggleSidebar}  onBurgerMenuClick = {onBurgerMenuClick}/>
        <div offset="75" className="css-main-content">
          <div className="css-content">
            <div style={{ width: "100%" }}> {/* maxWidth: 1200, */}
              <div id="anchor components -navbar --navbar">
                <div className="css-container">
                  <Switch>
                    <Route exact path="/" component={Dashboard} />
                    <Route exact path="/overview" component={Dashboard} />
                    <Route exact path="/customer" component={Customers} />
                    <Route exact path="/carers" component={Carers} />
                    <Route exact path="/payment-requests" component={PaymentRequests} />
                    <Route exact path="/payment-requests/details" component={DetailView} />
                    <Route exact path="/transactions/details" component={DetailView} />
                    <Route exact path="/transactions" component={Transactions} />
                    <Route exact path="/reports" component={Reports} />
                    <Route exact path="/customer/details" component={CustomerDetails} />
                    <Route exact path="/settings" component={SystemConfiguration} />
                  </Switch>
                </div>
                <footer className="footer mt-3">
                  <Bottombar />
                </footer>
              </div>
            </div>
          </div>
        </div>
        {/* <Bottombar /> */}
      </Container>
    </Container>
    {/* </div> */}
  </div>)
};

export default Content;
