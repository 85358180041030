import React, { createContext, useState, useEffect } from "react";
import Loader from "../common/Loader";

const AppContext = createContext(null);
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  return <Provider value={{}}>{children}</Provider>;
};

export { AppContext, AppProvider };
