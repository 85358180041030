import React, { useState, useEffect } from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

import logo from "../../assets/logo-no-background.png";
import overview from "../../assets/overview.png";
import account_holder from "../../assets/account-holder.png";
import carer from "../../assets/carer.png";
import payment_request from "../../assets/payment.png";
import transaction from "../../assets/transaction.png";
import report from "../../assets/report.png";
import configuration from "../../assets/configuration.png";
import redirect from "../../assets/redirect.png";

const menuData = [
  {
    name: "Overview",
    link: "/overview",
    icon: overview,
  },
  {
    name: "Account Holders",
    link: "/customer",
    icon: account_holder,
  },
  {
    name: "Carers",
    link: "/carers",
    icon: carer,
  },
  {
    name: "Payment Requests",
    link: "/payment-requests",
    icon: payment_request,
  },
  {
    name: "Transactions",
    link: "/transactions",
    icon: transaction,
  },
  {
    name: "Reports",
    link: "/reports",
    icon: report,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: configuration,
  },
];

const SideBar = ({ isOpen, toggle }) => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const { href } = window.location;

  const onLinkClick = (item) => {
    setSelectedMenu(item.name);
  };

  const getMenuByHrefLocation = () => {
    if (href) {
      const lastItem = href.substring(href.lastIndexOf("/"));
      if (lastItem === "/") {
        return menuData[0].name;
      } else {
        const result = menuData.filter((item) => item.link === lastItem);
        if (result.length > 0) {
          return result[0].name;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    const pageName = getMenuByHrefLocation();
    if (pageName) {
      setSelectedMenu(pageName);
    }
  }, [selectedMenu]);

  return (
    // <div className="container sidebar-container nav-container">
    <Nav
      className={classNames(
        "container sidebar-container nav-container sidebar",
        { "is-open": isOpen }
      )}
    >
      {" "}
      {/*, "sidebar", { "is-open": isOpen })}> */}
      {/* <div className="os-host os-host-foreign os-host-resize-disabled css-17bgnci os-host-scrollbar-horizontal-hidden os-host-overflow os-host-overflow-y os-host-transition"> */}
      <div className="os-resize-observer-host observed">
        <div
          className="os-resize-observer"
          style={{ left: 0, right: "auto" }}
        ></div>
        {/* ::after */}
      </div>
      <div
        className="os-size-auto-observer observed"
        style={{ height: "calc(100% + 1px)", float: "left" }}
      >
        <div className="os-resize-observer"></div>
      </div>
      <div className="os-content-glue" style={{ margin: -20 }}></div>
      <div className="os-padding">
        <div
          className="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid"
          style={{ overflowY: "scroll" }}
        >
          <div
            className="os-content"
            style={{ padding: '10px 20px 20px 20px', height: "100%", width: "100%" }}
          >
            {/* ::before */}
            <div className="css-1wpdhyu">
              <div className="sidebar-header css-axrk4p">
                <div className="css-1lsh4kf">
                  <img
                    className="css-logo"
                    src={logo}
                    alt="HealthMoni"
                    width={150}
                    height={60}
                  />
                </div>
              </div>
              <div tabIndex={0} id="explorer-menu" className="css-sidemenu">
                <div style={{ display: "block" }}>
                  <div className="css-sideitems">
                    <div className="css-sideitem">
                      <div className="side-menu">
                        {/* <Nav vertical className="list-unstyled pb-3"> */}
                        <div>
                          {menuData.map((item, index) => {
                            return (
                              <NavItem
                                key={index}
                                className={
                                  item.name === selectedMenu ? "active" : ""
                                }
                                onClick={() => {
                                  onLinkClick(item);
                                }}
                              >
                                <div className="mymenu-item">
                                  <NavLink
                                    tag={Link}
                                    to={item.link}
                                    className="mymenu-item"
                                  >
                                    <div className="side-menu-item">
                                      <img
                                        className="m-2 app-logo"
                                        src={item.icon}
                                        alt={"img_" + index}
                                        width={25}
                                        height={25}
                                      />
                                      <div className="text-dark fs-6">
                                        {item.name}
                                      </div>
                                    </div>
                                  </NavLink>
                                </div>
                              </NavItem>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className='redirect-container'>API Documentation
                <img src={redirect}
                  alt='redirect'
                  className="img-redirect"
                  />
              </div> */}
            </div>
            {/* ::after */}
          </div>
        </div>
      </div>
    </Nav>
  );
};
export default SideBar;
