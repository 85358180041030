import classNames from "classnames";
import { Container, Row, Col } from "reactstrap";

const Bottombar = () => {
  return (
    <Container>
      <Row>
          <Col xs="6" md="2" style={{paddingLeft: 10}}><a href="#" target="_blank">About</a></Col>
          <Col xs="6" md="2"><a href="#" target="_blank">FAQ</a></Col>
          <Col xs="6" md="2" style={{paddingLeft: 10}}><a href="#" target="_blank">Contact Us</a></Col>
          <Col xs="6" md="2"><a href="#" target="_blank">Data Confidentiality</a></Col>
          <Col xs="6" md="2" style={{paddingLeft: 10}}><a href="#" target="_blank">Privacy Policy</a></Col>
          <Col xs="6" md="2"><a href="#" target="_blank">Terms & Conditions</a></Col>
      </Row>
      <hr className="footer-divider" />
        <Row>
          <Col style={{paddingLeft: 10, paddingRight: 10}}>
            Copyright 2024 by HealthMoni Ltd. All Rights Reserved.
          </Col>
        </Row>
      <span className="span1-5"></span>
    </Container>
    // <div className="bottombar">
    //   <div className="child">
    //     <div style={{ display: "flex" }}>
    //       <a href="#" target="_blank" className="childItem">
    //         About
    //       </a>
    //       <a href="#" target="_blank" className="childItem">
    //         FAQ
    //       </a>
    //       <a href="#" target="_blank" className="childItem">
    //         Contact Us
    //       </a>
    //       <a href="#" target="_blank" className="childItem">
    //         Data Confidentiality
    //       </a>
    //       <a href="#" target="_blank" className="childItem">
    //         Privacy Policy
    //       </a>
    //       <a href="#" target="_blank" className="childItem">
    //         Terms & Conditions
    //       </a>
    //     </div>
    //     <span className="span0" style={{ paddingTop: "8px" }}>
    //       Copyright 2024 by HealthMoni Ltd. All Rights Reserved.
    //     </span>
    //     <span className="span1-5"></span>
    //   </div>
    // </div>
  );
};

export default Bottombar;
