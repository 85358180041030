import { Form, FormGroup, Label } from "reactstrap";

const DetailLabelValue = (props) => {
  const { label, value } = props;

  return (
    <div className="detail-label">
      <Label style={{fontWeight: 500}}>{label}:</Label>
      <Label>{value}</Label>
    </div>
  );
};

export default DetailLabelValue;
