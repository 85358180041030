import React from "react";
import { Form, FormGroup, Label, Col, Row } from "reactstrap";
import { addressFormatter } from "../../common/Helper";

const CustomerInformation = (props) => {
  const { item, address } = props;

  return (
    <>
      {(
        <Form>
          <FormGroup>
            <Label for="firstname" sm={2} style={{fontWeight: 500}}>
              First Name:
            </Label>
            <Label for="firstname" sm={10}>
              {item.firstName}
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="lastname" sm={2} style={{fontWeight: 500}}>
              Last Name:
            </Label>
            <Label for="lastname" sm={10}>
              {item.lastName}
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="dob" sm={2} style={{fontWeight: 500}}>
              Date Of Birth:
            </Label>
            <Label for="dob" sm={10}>
              {item.dob}
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="mobile" sm={2} style={{fontWeight: 500}}>
              Mobile:
            </Label>
            <Label for="mobile" sm={10}>
              {item.mobileNo}
            </Label>
          </FormGroup>

          <FormGroup>
            <Label for="email" sm={2} style={{fontWeight: 500}}>
              Email:
            </Label>
            <Label for="email" sm={10}>
              {item.email}
            </Label>
          </FormGroup>

          <FormGroup >
            <Label for="address" sm={2} style={{fontWeight: 500}}>
              Address:
            </Label>
            <Label for="address" sm={10}>
              {address && address.length > 0
                ? addressFormatter(address[0])
                : ""}
            </Label>
          </FormGroup>
        </Form>
        
        //   <Form>
        //   <FormGroup row>
        //     <Label for="firstname" sm={2}>
        //       First Name:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="firstname" sm={10}>
        //         {item.firstName}
        //       </Label>
        //     </Col>
        //   </FormGroup>
        //   <FormGroup row>
        //     <Label for="lastname" sm={2}>
        //       Last Name:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="lastname" sm={10}>
        //         {item.lastName}
        //       </Label>
        //     </Col>
        //   </FormGroup>
        //   <FormGroup row>
        //     <Label for="dob" sm={2}>
        //       Date Of Birth:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="dob" sm={10}>
        //         {item.dob}
        //       </Label>
        //     </Col>
        //   </FormGroup>

        //   <FormGroup row>
        //     <Label for="mobile" sm={2}>
        //       Mobile:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="mobile" sm={10}>
        //         {item.mobileNo}
        //       </Label>
        //     </Col>
        //   </FormGroup>

        //   <FormGroup row>
        //     <Label for="email" sm={2}>
        //       Email:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="email" sm={10}>
        //         {item.email}
        //       </Label>
        //     </Col>
        //   </FormGroup>

        //   <FormGroup row>
        //     <Label for="address" sm={2}>
        //       Address:
        //     </Label>
        //     <Col sm={10}>
        //       <Label for="address" sm={10}>
        //         {address && address.length > 0
        //           ? addressFormatter(address[0])
        //           : ""}
        //       </Label>
        //     </Col>
        //   </FormGroup>

        // </Form>
      )}
    </>
  );
};

export default CustomerInformation;
