import React, { useContext, useState, useEffect } from "react";

import { Container, Table, Row, Col } from "reactstrap";
import { dateFormatByDayMonthYear } from "../../common/Helper";

const AssociatedCarers = (props) => {
  const { item } = props;
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="tableHeight">
            <Table hover className="mytable">
              <thead>
                <tr>
                  <th className="myth">Name</th>
                  <th className="myth">Status</th>
                  <th className="myth">Date</th>
                </tr>
              </thead>
              <tbody>
                {item.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.status}</td>
                      <td>{dateFormatByDayMonthYear(item.createdDate)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AssociatedCarers;
