import React, { useState, useEffect } from "react";
import {
  Navbar,
  Button,
  Collapse,
  Nav,
} from "reactstrap";

//  import { FaUserCircle } from 'react-icons/fa';

import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/logo-no-background.png";
import burgerMenu from "../../assets/burger-menu.png";
import signout from "../../assets/signout.png";
import usericon from "../../assets/user.png";

import { getLoggedInHMPUser } from "../../services/APIServices";

const Topbar = (props) => {
  const { onBurgerMenuClick } = props;

  const [topbarIsOpen] = useState(true);
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [width, setWidth] = useState(window.innerWidth);
  const [loggedInUser, setLoggedinUser] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  useEffect(() => {
    getLoggedInUser()
  }, [user])

  const getLoggedInUser = async () => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();

      getLoggedInHMPUser(token)
        .then((response) => {
          setLoggedinUser(response);
         })
        .catch((err) => { });
    }
  };

  const logoutWithRedirect = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const onSidebarMobile = () => {
    onBurgerMenuClick(true);
  };

  return (
    <div className="os-host os-host-foreign os-host-resize-disabled css-topbar os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition">
      <div className="os-resize-observer-host observed">
        <div
          className="os-resize-observer"
          style={{ left: 0, right: "auto" }}
        ></div>
        {/* ::after */}
      </div>
      <div
        className="os-size-auto-observer observed"
        style={{ height: "calc(100% + 1px)", float: "left" }}
      >
        <div className="os-resize-observer"></div>
      </div>
      <div className="os-content-glue" style={{ margin: 0, height: 74 }}></div>
      <div className="os-padding">
        <div className="os-viewport os-viewport-native-scrollbars-invisible">
          <div
            className="os-content"
            style={{ padding: 0, height: "100%", width: "100%" }}
          >
            {/* ::before */}
            <div className="css-top-content">
              {width <= 768 && (
                <div className="sidebar-header css-axrk4p">
                  <div className="css-1lsh4kf">
                    <img
                      className="css-logo"
                      src={logo}
                      alt="HealthMoni"
                      width={120}
                      height={48}
                    />
                  </div>
                </div>
              )}
              {/* <Button className="menu-button d-md-none" onClick={toggleSidebar}>
                ☰
              </Button> */}
              <div className="css-signout-container">
                <Navbar className="navbar-noshadow" expand="md">
                  {" "}
                  {/* color="white"*/}
                  {/* <NavbarToggler className="me-2" onClick={toggleSidebar} /> */}
                  {width <= 768 ? (
                    <img
                      src={burgerMenu}
                      alt="HealthMoni"
                      width={50}
                      height={50}
                      onClick={onSidebarMobile}
                    />
                  ) : (
                    <Collapse
                      isOpen={topbarIsOpen}
                      navbar
                      style={{ height: 75 }}
                    >
                      {isAuthenticated && (
                        <Nav className="ml-auto" navbar>
                          {/* <Button
                            className="button"
                            onClick={logoutWithRedirect}
                          >
                            Sign Out
                          </Button> */}
                          <div>
                            <div className="username">
                              {loggedInUser && loggedInUser.user && loggedInUser.user.name}
                            </div>
                            <div className="user-email">
                              {user && user.email}
                            </div>
                          </div>
                          <img src={signout}
                            alt='signout'
                            className="signout" 
                            onClick={logoutWithRedirect}/>
                        </Nav>
                      )}
                    </Collapse>
                  )}
                </Navbar>
              </div>
            </div>
            {/* ::after */}
          </div>
        </div>
      </div>
      <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
        <div className="os-scrollbar-track os-scrollbar-track-off">
          <div
            className="os-scrollbar-handle"
            style={{ width: "100%", transform: "translate(0px, 0px)" }}
          ></div>
        </div>
      </div>
      <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable os-scrollbar-auto-hidden">
        <div className="os-scrollbar-track os-scrollbar-track-off">
          <div
            className="os-scrollbar-handle"
            style={{ height: "100%", transform: "translate(Opx, 0px)" }}
          ></div>
        </div>
      </div>
      <div className="os-scrollbar-corner"></div>
    </div>
  );
};

export default Topbar;
