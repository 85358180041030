import rulesType from "../data/ruleMaster.json";

export const addressFormatter = (address) => {
  const addressLine1 = address.addressLine1 ? address.addressLine1 : null;
  const addressLine2 = address.addressLine2 ? address.addressLine2 : null;
  const addressLine3 = address.addressLine3 ? address.addressLine3 : null;
  const town = address.town ? address.town : null;
  const country = address.country ? address.country : null;
  const postCode = address.postCode ? address.postCode : null;
  var finalAddress = "";
  if (addressLine1) {
    finalAddress = addressLine1 + ", ";
  }
  if (addressLine2) {
    finalAddress = finalAddress + addressLine2 + ", ";
  }
  if (addressLine3) {
    finalAddress = finalAddress + addressLine3 + ", ";
  }
  if (town) {
    finalAddress = finalAddress + town + ", ";
  }
  if (country) {
    finalAddress = finalAddress + country + ", ";
  }
  if (postCode) {
    finalAddress = finalAddress + postCode;
  }
  return finalAddress;
};

export const ISODateFormatter = (dateString) => {
  return dateString.split("T")[0].replace(/-/g, "-");
};

export const dateFormatByDayMonthYear = (dateString) => {
  const date = new Date(dateString);
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const dateFormatNumericDayMonthYear = dateString => {
  const date = new Date(dateString);
  const options = {month: 'numeric', day: 'numeric', year: 'numeric'};
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

export const currencyFormatter = (amount) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  }).format(amount);
};

export const getRuleTypeById = (ruleId) => {
  const rule = rulesType.filter((data) => data.Id === parseInt(ruleId));
  if (rule.length > 0) {
    return rule[0].ruleType;
  } else {
    return null;
  }
};

export const emailValidator = text => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

export const validateUKPostcode = text => {
  var reg = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/gi;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

export const formatSortCode = sortCode => {
  return (String(sortCode).match(/.{1,2}/g) || []).join('-');
};

export const maskExceptLast4 = input => {
  if (!input || input.length <= 4) {
    return input;
  }
  const length = input.length;
  const maskedPart = '*'.repeat(length - 4);
  const visiblePart = input.slice(-4);
  return maskedPart + visiblePart;
};

export const sortCodeValidator = sortCode => {
  // let reg = /^(?!(?:0{6}|00-00-00))(?:\d{6}|(\d{2}-){2}\d{2})$/;
  let reg = /^(?:\d{6}|(\d{2}-){2}\d{2})$/; // Testing purpose only
  if (reg.test(sortCode) === false) {
    return false;
  } else {
    return true;
  }
};

const isNumber = number => {
  let reg = /^[0-9\b]+$/;
  if (reg.test(number) === false) {
    return false;
  } else {
    return true;
  }
};

export const accountNumberValidator = number => {
  return number.length === 8 && isNumber(number);
};

export const phoneNumberValidator = number => {
  return (
    number.length === 11 && isNumber(number) && parseInt(number.charAt(0)) === 0
  );
};


export const validateErrorAndReturnErrorMessage = error => {
  try {
    const errorJson = JSON.parse(error.message);
    if (
      errorJson.status === 0 &&
      errorJson.name === 'a0.credential_manager.invalid'
    ) {
      return 'REFRESH_TOKEN_EXPIRED';
    } else if (errorJson.apierror && errorJson.apierror.subErrors) {
      return errorJson.apierror.subErrors;
    } else if (errorJson.apierror && errorJson.apierror.responseMessage) {
      return [{message: errorJson.apierror.responseMessage}];
    } else if (errorJson.error) {
      return [{message: errorJson.error}];
    } else if (errorJson.Message) {
      return [{message: errorJson.Message}];
    } else if (errorJson.responseMessage) {
      return [{message: errorJson.responseMessage}];
    } else {
      return [{message: 'Invalid error structure'}];
    }
  } catch (ex) {
    return [{message: 'Invalid error structure'}];
  }
};

export const validateErrorAndReturnWithRestructure = errorMessage => {
  return [{message: errorMessage}];
};
