import React, { useState, useEffect } from "react";

import { Container, Table, Row, Col } from "reactstrap";
import Loader from "../../common/Loader";
import { fetchCarer } from "../../services/APIServices";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { dateFormatByDayMonthYear } from "../../common/Helper";
import { useAuth0 } from "@auth0/auth0-react";

const PAGE_SIZE = 10;

const Carers = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [carers, setCarers] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const payload = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
    };
    fetchData(payload);
  }, []);

  const fetchData = async (payload) => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    fetchCarer(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setCarers(response);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onPageNumClick = async (index) => {
    if (parseInt(index) === parseInt(carers.currentPage)) {
      return;
    }
    const payload = {
      pageNumber: index,
      pageSize: PAGE_SIZE,
    };
    fetchData(payload);
  };

  return (
    <Container fluid>
      <h4> Carers</h4>
      {carers && carers.data && carers.data.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>There are no carers.</h6>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 30 }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            {carers && carers.data && carers.data.length > 0 && (
              <PaginationControl
                page={carers.currentPage}
                between={3}
                total={carers.totalPages}
                limit={1}
                changePage={onPageNumClick}
                ellipsis={1}
                last={true}
              />
            )}
          </div>
          <div
            style={{
              minHeight: "200px",
              maxHeight: "600px",
              overflowY: "auto",
            }}
          >
            {carers && carers.data && carers.data.length > 0 && (
              <Container fluid>
                <Row>
                  <Col>
                    <div className="tableHeight">
                      <Table
                        striped
                        bordered
                        className="mytable table.table-bordered"
                      >
                        <thead>
                          <tr className="table-primary">
                            <th className="myth">Name</th>
                            <th className="myth">Carer Type</th>
                            <th className="myth">Status</th>
                            <th className="myth">Using RtP</th>
                            <th className="myth">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {carers.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.beneficiaryName}</td>
                                <td>{item.beneficiaryType}</td>
                                <td>{item.status}</td>
                                <td>{item.usingRtP ? "Yes" : "No"}</td>
                                <td>
                                  {dateFormatByDayMonthYear(item.createdDate)}
                                </td>
                                {/* <td>
                            <Button onClick={() => onUploadClick(item)}>
                              Upload
                            </Button>
                          </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </>
      )}
      {showLoader && (
        <div style={{ zIndex: 5 }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default Carers;
