import React, { useEffect, useState } from "react";

import {
  Navbar,
  Container,
  Nav,
  Label,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import Loader from "../../common/Loader";
import { DatePicker } from "reactstrap-date-picker";
import { fetchOverviewData } from "../../services/APIServices";
import {
  ISODateFormatter,
  dateFormatNumericDayMonthYear,
} from "../../common/Helper";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const Dashboard = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const startDate = new Date();
  startDate.setUTCDate(startDate.getUTCDate() - 90);
  const [startDateValue, setStartDateValue] = useState(startDate.toISOString());
  const [endDateValue, setEndDateValue] = useState(new Date().toISOString());

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [overview, setOverview] = useState({
    customers: null,
    carers: null,
    paymentRequests: null,
    transactions: null,
    aggPaymentRequests: null,
    aggTransactions: null,
    flaggedTransactionCount: null,
  });

  const handleChangeStartDate = (value, formattedValue) => {
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate() - 90);
      setStartDateValue(date.toISOString());
    } else {
      setStartDateValue(value);
    }
  };

  const handleChangeEndDate = (value, formattedValue) => {
    if (value === null) {
      const date = new Date();
      date.setUTCDate(date.getUTCDate());
      setEndDateValue(date.toISOString());
    } else {
      setEndDateValue(value);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDateValue, endDateValue]);

  const fetchData = async () => {
    const token = await getAccessTokenSilently();
    const custPayload = {
      pageNumber: 1,
      pageSize: 1,
    };
    const carerPayload = {
      pageNumber: 1,
      pageSize: 1,
    };
    const prPayload = {
      pageNumber: 1,
      pageSize: 1,
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    const transPayload = {
      pageNumber: 1,
      pageSize: 1,
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    const aggPRPayload = {
      startDate: ISODateFormatter(startDateValue),
      endDate: ISODateFormatter(endDateValue),
    };
    setShowLoader(true);
    fetchOverviewData(
      token,
      custPayload,
      carerPayload,
      prPayload,
      transPayload,
      aggPRPayload,
      aggPRPayload
    )
      .then((response) => {
        setOverview({
          customers: response.customers ? response.customers : null,
          carers: response.carers ? response.carers : null,
          paymentRequests: response.paymentRequests
            ? response.paymentRequests
            : null,
          transactions: response.transactions ? response.transactions : null,
          aggPaymentRequests: response.aggPaymentRequest
            ? response.aggPaymentRequest
            : null,
          aggTransactions: response.aggTransactions
            ? response.aggTransactions
            : null,
        });
      })
      .catch((err) => {
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const organiseBarChartDataset = (type) => {
    if (type === 1) {
      return {
        labels: overview.aggPaymentRequests.data.map((item) =>
          dateFormatNumericDayMonthYear(item.label)
        ),
        datasets: [
          {
            label: "No. of Payment Requests",
            data: overview.aggPaymentRequests.data.map((item) => item.count),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      };
    } else {
      return {
        labels: overview.aggTransactions.data.map((item) =>
          dateFormatNumericDayMonthYear(item.label)
        ),
        datasets: [
          {
            label: "No. of Transactions",
            data: overview.aggTransactions.data.map((item) => item.count),
            backgroundColor: "rgba(109, 127, 204, 0.75)",
          },
        ],
      };
    }
  };

  if (!isAuthenticated) {
    return <Loader />;
  }
  return (
    <Container fluid>
      <h4>Overview</h4>
      <Navbar className="p-4 mb-2 mt-2" expand="md">
        <Nav className="ml-auto" pills fill>
          <div className="datepicker-container">
            <div className="datepicker">
              <Label className="nowrap">Start Date</Label>
              <DatePicker
                id="start-datepicker"
                value={startDateValue}
                onChange={(v, f) => handleChangeStartDate(v, f)}
              />
            </div>
            <div className="datepicker">
              <Label className="nowrap">End Date</Label>
              <DatePicker
                id="end-datepicker"
                value={endDateValue}
                onChange={(v, f) => handleChangeEndDate(v, f)}
              />
            </div>
          </div>
        </Nav>
      </Navbar>
      <Row style={{ marginTop: 10, marginLeft: 10, justifyContent: "center" }}>
        <Col>
          <Card className="my-2" color="primary" outline style={{}}>
            <CardBody className="cardtitle">
              <CardTitle tag="h5">
                {overview.customers ? overview.customers.totalItems : ""}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Account Holders
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="my-2" color="warning" outline style={{}}>
            <CardBody className="cardtitle">
              <CardTitle tag="h5">
                {overview.carers ? overview.carers.totalItems : ""}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Carers
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="my-2" color="success" outline style={{}}>
            <CardBody className="cardtitle">
              <CardTitle tag="h5">
                {overview.paymentRequests
                  ? overview.paymentRequests.totalItems
                  : ""}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Payment Requests
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="my-2" color="info" outline style={{}}>
            <CardBody className="cardtitle">
              <CardTitle tag="h5">
                {overview.transactions ? overview.transactions.totalItems : ""}
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6">
                Transactions
              </CardSubtitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 10, marginLeft: 10, marginRight: 5 }}>
        <Col sm="6">
          <Card
            className="my-2"
            color="danger"
            outline
            style={{
              height: "95%",
            }}
          >
            <CardBody>
              <CardTitle tag="h5">Payment Requests by Date</CardTitle>
              {overview.aggTransactions && overview.aggTransactions.data && (
                <Bar options={options} data={organiseBarChartDataset(1)} />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card
            className="my-2"
            color="secondary"
            outline
            style={{
              height: "95%",
            }}
          >
            <CardBody>
              <CardTitle tag="h5">Transactions by Date</CardTitle>
              {overview.aggPaymentRequests &&
                overview.aggPaymentRequests.data && (
                  <Bar options={options} data={organiseBarChartDataset(2)} />
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showLoader && (
        <div style={{ zIndex: 5 }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default Dashboard;
