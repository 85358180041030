import React from "react";
import { dateFormatByDayMonthYear, currencyFormatter } from "./Helper";
import DetailLabelValue from "./DetailLabelValue";
import { getRuleTypeById } from "./Helper";

const DetailViewInformation = (props) => {
  const { item, pageFrom, cardType } = props;

  return (
    <div>
      {pageFrom && pageFrom === "/transactions" ? (
        <div>
          <DetailLabelValue
            label={"Full Name"}
            value={
              cardType === 1
                ? item.payerName
                  ? item.payerName
                  : item.firstName + " " + item.lastName
                : item.isCredit
                ? item.firstName + " " + item.lastName
                : item.payee
            }
          />
          <DetailLabelValue
            label={"Sort Code"}
            value={
              cardType === 1
                ? item.isCredit
                  ? item.payerSortCode
                  : item.sortCode
                : item.isCredit
                ? item.sortCode
                : item.benSortCode
            }
          />
          <DetailLabelValue
            label={"Account Number"}
            value={
              cardType === 1
                ? item.isCredit
                  ? item.payerAccountNo
                  : item.accountNo
                : item.isCredit
                ? item.accountNo
                : item.benAccountNo
            }
          />
          <DetailLabelValue
            label={"Amount"}
            value={currencyFormatter(item.amount)}
          />
          <DetailLabelValue
            label={"Transaction Date"}
            value={dateFormatByDayMonthYear(item.transactionDate)}
          />

          {item.ruleType && (
            <DetailLabelValue
              label={"Exception Type"}
              value={getRuleTypeById(item.ruleType)}
            />
          )}
        </div>
      ) : (
        <div>
          <DetailLabelValue
            label={"Full Name"}
            value={
              cardType === 1
                ? item.firstName + " " + item.lastName
                : item.beneficiaryName
            }
          />
          <DetailLabelValue
            label={"Sort Code"}
            value={cardType === 1 ? item.sortCode : item.benSortCode}
          />
          <DetailLabelValue
            label={"Account Number"}
            value={cardType === 1 ? item.accountNo : item.benAccountNo}
          />
          <DetailLabelValue
            label={"Amount"}
            value={currencyFormatter(item.amount)}
          />
          <DetailLabelValue
            label={"Request Date"}
            value={dateFormatByDayMonthYear(item.prCreatedDate)}
          />
          <DetailLabelValue
            label={"Payment Date"}
            value={
              item.pcreatedDate
                ? dateFormatByDayMonthYear(item.pcreatedDate)
                : ""
            }
          />
        </div>
      )}
    </div>
  );
};

export default DetailViewInformation;
