import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { savePartnerDetails } from "../../services/APIServices";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../common/Loader";

const PartnerDetailsForm = (props) => {
  const { title, show, onShowPopup, details, onDetailsUpdate } = props;
  const [modal, setModal] = useState(show);
  const [showLoader, setShowLoader] = useState(false);
  const [partnerDetail, setPartnerDetail] = useState(
    details
      ? details
      : {
          name: undefined,
          email: undefined,
          businessName: undefined,
          phoneNo: undefined,
          address: {
            addressLine1: undefined,
            addressLine2: undefined,
            addressLine3: undefined,
            postCode: undefined,
          },
        }
  );

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {}, []);

  const toggle = () => {
    setModal(!modal);
    onShowPopup(!modal);
  };

  const createOrUpdatePartnerDetailsWithData = async (payload) => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    savePartnerDetails(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          toggle();
          if (onDetailsUpdate) {
            onDetailsUpdate(response.details);
          }
        } else {
          alert(response.responseMessage);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onClickSubmit = () => {
    if (!validateFormData()) {
      return;
    }
    const payload = {
      name: partnerDetail.name,
      email: partnerDetail.email,
      businessName: partnerDetail.businessName,
      phoneNo: partnerDetail.phoneNo,
      address: {
        addressLine1: partnerDetail.address
          ? partnerDetail.address.addressLine1
          : "",
        addressLine2: partnerDetail.address
          ? partnerDetail.address.addressLine2
          : "",
        addressLine3: partnerDetail.address
          ? partnerDetail.address.addressLine3
          : "",
        postCode: partnerDetail.address ? partnerDetail.address.postCode : "",
      },
    };

    createOrUpdatePartnerDetailsWithData(payload);
  };

  const validateFormData = () => {
    if (!partnerDetail.name) {
      alert("Please enter the name");
      return false;
    } else if (!partnerDetail.email) {
      alert("Please enter the email id");
      return false;
    } else if (!partnerDetail.businessName) {
      alert("Please enter the business name");
      return false;
    } else if (!partnerDetail.phoneNo) {
      alert("Please enter the phone number");
      return false;
    } else if (
      partnerDetail.address &&
      partnerDetail.address.addressLine1 &&
      !partnerDetail.address.postCode
    ) {
      alert("Please enter the postcode");
      return false;
    } else {
      return true;
    }
  };

  const onNameEnter = (value) => {
    setPartnerDetail({ ...partnerDetail, name: value });
  };

  const onEmailEnter = (value) => {
    setPartnerDetail({ ...partnerDetail, email: value });
  };

  const onBusinessEnter = (value) => {
    setPartnerDetail({ ...partnerDetail, businessName: value });
  };

  const onPhoneEnter = (value) => {
    setPartnerDetail({ ...partnerDetail, phoneNo: value });
  };

  const onAddressLine1Enter = (value) => {
    setPartnerDetail({
      ...partnerDetail,
      address: { ...partnerDetail.address, addressLine1: value },
    });
  };

  const onAddressLine2Enter = (value) => {
    setPartnerDetail({
      ...partnerDetail,
      address: { ...partnerDetail.address, addressLine2: value },
    });
  };

  const onAddressLine3Enter = (value) => {
    setPartnerDetail({
      ...partnerDetail,
      address: { ...partnerDetail.address, addressLine3: value },
    });
  };

  const onPostCodeEnter = (value) => {
    setPartnerDetail({
      ...partnerDetail,
      address: { ...partnerDetail.address, postCode: value },
    });
  };

  return (
    <Modal
      isOpen={modal}
      fade={true}
      toggle={toggle}
      centered={true}
      backdrop={false}
      size="lg"
    >
      <ModalHeader className="modal-header-bg-color" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="name" className="h6">
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="input"
                  value={partnerDetail.name}
                  placeholder="Type here"
                  onChange={(e) => onNameEnter(e.target.value)}
                ></Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="emailid" className="h6">
                  Email Id
                </Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Type here"
                  value={partnerDetail.email}
                  onChange={(e) => onEmailEnter(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="businessname" className="h6">
                  Business Name
                </Label>
                <Input
                  id="businessname"
                  name="businessname"
                  placeholder="Type here"
                  value={partnerDetail.businessName}
                  onChange={(e) => onBusinessEnter(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="phone" className="h6">
                  Phone Number
                </Label>
                <Input
                  id="phone"
                  name="phone"
                  placeholder="Type here"
                  value={partnerDetail.phoneNo}
                  onChange={(e) => onPhoneEnter(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div>
              <strong>Address</strong>
            </div>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="add1" className="h6">
                        Address Line 1
                      </Label>
                      <Input
                        id="addline1"
                        name="addline1"
                        placeholder="Type here"
                        value={
                          partnerDetail.address &&
                          partnerDetail.address.addressLine1
                        }
                        onChange={(e) => onAddressLine1Enter(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="add2" className="h6">
                        Address Line 2
                      </Label>
                      <Input
                        id="addline2"
                        name="addline2"
                        placeholder="Type here"
                        value={
                          partnerDetail.address &&
                          partnerDetail.address.addressLine2
                        }
                        onChange={(e) => onAddressLine2Enter(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="add3" className="h6">
                        Address Line 3
                      </Label>
                      <Input
                        id="addline3"
                        name="addline3"
                        placeholder="Type here"
                        value={
                          partnerDetail.address &&
                          partnerDetail.address.addressLine3
                        }
                        onChange={(e) => onAddressLine3Enter(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="postcode" className="h6">
                        Postcode
                      </Label>
                      <Input
                        id="postcode"
                        name="postcode"
                        placeholder="Type here"
                        value={
                          partnerDetail.address &&
                          partnerDetail.address.postCode
                        }
                        onChange={(e) => onPostCodeEnter(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter className="modal-header-bg-color">
        <Button className="button-back" onClick={onClickSubmit}>
          {title}
        </Button>
      </ModalFooter>
      {showLoader && <Loader />}
    </Modal>
  );
};

export default PartnerDetailsForm;
