import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Loader from "../../common/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getPartnerDetails,
  createAPIAndSecretKey,
  resetSecretKey,
} from "../../services/APIServices";

import PartnerDetailsInfo from "./PartnerDetailsInfo";
import PartnerDetailsForm from "./PartnerDetailsForm";
import PartnerBankDetailsForm from "./PartnerBankDetailsForm";
import PartnerBankDetailsInfo from "./PartnerBankDetailsInfo";
import PartnerIntegrationDetails from "./PartnerIntegrationDetails";
import ModalPopup from "../../common/ModalPopup";

const SystemConfiguration = (props) => {
  const [open, setOpen] = useState("1");
  const [showLoader, setShowLoader] = useState(false);
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [showBankDetailModalPopup, setShowBankDetailModalPopup] =
    useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [partnerDetail, setPartnerDetail] = useState(undefined);

  const [selectedKeyType, setSelectedKeyType] = useState(undefined);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const fetchData = async () => {
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    getPartnerDetails(token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setPartnerDetail(response.details);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const openPopup = () => {
    setShowModalPopup(!showModalPopup);
  };

  const onUpdateDetails = () => {
    setShowModalPopup(!showModalPopup);
  };

  const openBankDetailPopup = () => {
    setShowBankDetailModalPopup(!showBankDetailModalPopup);
  };

  const onUpdateBankDetails = () => {
    setShowBankDetailModalPopup(!showBankDetailModalPopup);
  };

  const generateKeys = async (type) => {
    const payload = {
      keyType: type,
    };
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    createAPIAndSecretKey(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setPartnerDetail(response.details);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onGenerateKeys = (type) => {
    generateKeys(type);
  };

  const resetAPISecretKey = async (type) => {
    const payload = {
      keyType: type,
    };
    const token = await getAccessTokenSilently();
    setShowLoader(true);
    resetSecretKey(payload, token)
      .then((response) => {
        if (
          response.responseCode === 0 &&
          response.responseMessage === "Success"
        ) {
          setPartnerDetail(response.details);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onResetAPISecretKeys = (type) => {
    setSelectedKeyType(type);
    setShowWarningPopup(true);
  };

  const onShowPopup = (status) => {
    setShowWarningPopup(status);
  };

  const onPopupYes = (status) => {
    setShowWarningPopup(status);
    resetAPISecretKey(selectedKeyType);
  };

  const onDetailsUpdate = (details) => {
    setPartnerDetail(details);
  };

  return (
    <Container fluid>
      <h4>{"Settings"}</h4>
      <Container fluid>
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              <h5>Details</h5>
            </AccordionHeader>
            {!showLoader && (
              <AccordionBody accordionId="1">
                {!partnerDetail ? (
                  <Button
                    className="button-back"
                    onClick={openPopup}
                    style={{ marginBottom: "1rem" }}
                  >
                    Add Details
                  </Button>
                ) : (
                  <>
                    <PartnerDetailsInfo partnerDetail={partnerDetail} />
                    <div className="partner-detail-update">
                      <Button
                        className="button-back"
                        onClick={() => onUpdateDetails()}
                      >
                        Update Details
                      </Button>
                    </div>
                  </>
                )}
              </AccordionBody>
            )}
          </AccordionItem>
          {partnerDetail && (
            <AccordionItem>
              <AccordionHeader targetId="2">
                <h5>Bank Details</h5>
              </AccordionHeader>
              <AccordionBody accordionId="2">
                {!partnerDetail.account.accountName ? (
                  <Button
                    className="button-back"
                    onClick={openBankDetailPopup}
                    style={{ marginBottom: "1rem" }}
                  >
                    Add Bank Details
                  </Button>
                ) : (
                  <>
                    <PartnerBankDetailsInfo
                      bankDetail={partnerDetail.account}
                    />
                    <div className="partner-detail-update">
                      <Button
                        className="button-back"
                        onClick={() => onUpdateBankDetails()}
                      >
                        Update Bank Details
                      </Button>
                    </div>
                  </>
                )}
              </AccordionBody>
            </AccordionItem>
          )}
          {partnerDetail && (
            <AccordionItem>
              <AccordionHeader targetId="3">
                <h5>Integration Details</h5>
              </AccordionHeader>
              <AccordionBody accordionId="3">
                <PartnerIntegrationDetails
                  header={"Sandbox API Keys"}
                  partnerDetail={partnerDetail}
                  type={"sandbox"}
                  onGenerateKeys={onGenerateKeys}
                  onResetAPISecretKeys={onResetAPISecretKeys}
                />
                <PartnerIntegrationDetails
                  header={"Production API Keys"}
                  partnerDetail={partnerDetail}
                  type={"production"}
                  onGenerateKeys={onGenerateKeys}
                  onResetAPISecretKeys={onResetAPISecretKeys}
                />
                <PartnerIntegrationDetails
                  header={"App Links"}
                  partnerDetail={partnerDetail}
                  type={"applinks"}
                />
              </AccordionBody>
            </AccordionItem>
          )}
        </Accordion>
      </Container>
      {showLoader && <Loader />}
      {showModalPopup && (
        <PartnerDetailsForm
          title={partnerDetail === undefined ? "Add Details" : "Update Details"}
          details={partnerDetail}
          show={showModalPopup}
          onShowPopup={openPopup}
          onDetailsUpdate={onDetailsUpdate}
        />
      )}
      {showBankDetailModalPopup && (
        <PartnerBankDetailsForm
          title={
            !partnerDetail.account.accountName
              ? "Add Account Details"
              : "Update Account Details"
          }
          details={partnerDetail}
          show={showBankDetailModalPopup}
          onShowPopup={openBankDetailPopup}
          onDetailsUpdate={onDetailsUpdate}
        />
      )}
      {showWarningPopup && (
        <ModalPopup
          title={"Reset"}
          message={
            "Are you sure you want to reset the secret key? Resetting it will require updating it wherever it is currently in use."
          }
          show={showWarningPopup}
          onShowPopup={onShowPopup}
          onPopupYes={onPopupYes}
        />
      )}
    </Container>
  );
};

export default SystemConfiguration;
