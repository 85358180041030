import axios from "axios";

import { BASE_URL } from "../common/constants";
import HttpException from "../common/exceptions/HttpException";

export const fetchCustomer = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrieveCustomers", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchCarer = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrievePayees", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchPaymentRequest = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrievePaymentRequests", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchAggPaymentRequest = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrieveAggPaymentRequests", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchTransaction = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrieveTransactions", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchAggTransaction = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrieveAggTransactions", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchCustomerInfo = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrieveCustomerInfo", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchPayeeByCustomer = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/retrievePayeeByCustomer", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchDocumentByOwnerId = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/storage/v1/getDocumentByOwner", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const fetchOverviewData = async (
  token,
  customerPayload,
  carerPayload,
  prPayload,
  transPayload,
  aggPRPayload,
  aggTPayload,
) => {
  const [
    customers,
    carers,
    paymentRequests,
    transactions,
    aggPaymentRequest,
    aggTransactions,
  ] = await Promise.all([
    fetchCustomer(customerPayload, token),
    fetchCarer(carerPayload, token),
    fetchPaymentRequest(prPayload, token),
    fetchTransaction(transPayload, token),
    fetchAggPaymentRequest(aggPRPayload, token),
    fetchAggTransaction(aggTPayload, token),
  ]);
  return {
    customers,
    carers,
    paymentRequests,
    transactions,
    aggPaymentRequest,
    aggTransactions,
  };
};

export const getPartnerDetails = async (token) => {
  const response = await axios
    .get(BASE_URL + "/api/partner/v1/getPartnerDetails", {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const savePartnerDetails = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/savePartnerDetails", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const savePartnerBankDetails = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/savePartnerBankDetails", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const createAPIAndSecretKey = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/createAPIAndSecretKey", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const resetSecretKey = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/resetSecretKey", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const getLoggedInHMPUser = async (token) => {
  const response = await axios
    .get(BASE_URL + "/api/user/v1/getLoggedInHMPUser", {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const downloadReport = async (payload, token) => {
  const response = await axios
    .post(BASE_URL + "/api/partner/v1/downloadReport", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        // "Cache-Control": "no-store",
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testSendOtp = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/send-otp", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testLinkCustomerAccount = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/link-customer-account", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testVerifyBeneficiaryName = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/verify-beneficiary-name", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testRegisterBeneficiary = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/register-beneficiary", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testRegisterBeneficiaryWithOtp = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/register-beneficiary-with-otp", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testStartPaymentRequest = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/initiate-payment-request", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testSendPaymentRequest = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/send-payment-request", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testSendPaymentRequestWithOtp = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/send-payment-request-with-otp", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};

export const testUploadDocument = async (payload, apiKey, hmac) => {
  const response = await axios
    .post(BASE_URL + "/integration/partner-api/v1/upload-document", payload, {
      headers: {
        Authorization: `${apiKey}`,
        "X-HMAC-Hash": hmac,
      },
    })
    .catch(HttpException.create);
  return response.data;
};





