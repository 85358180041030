import React from "react";
import { Form, FormGroup, Row, Col, Label } from "reactstrap";

const PartnerBankDetailsInfo = (props) => {
  const { bankDetail } = props;
  return (
    <Form>
      <Row>
        <Col>
          <FormGroup>
            <Label for="name" className="h6">
              {"Account Name:"}
            </Label>
            <Label for="namevalue" className="h6 partner-detail-into">
              {bankDetail.accountName}
            </Label>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="sortcode" className="h6">
              Sort Code:
            </Label>
            <Label for="sortcodevalue" className="h6 partner-detail-into">
              {bankDetail.sortCode}
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label for="accountno" className="h6">
              Account Number:
            </Label>
            <Label for="accountnovalue" className="h6 partner-detail-into">
              {bankDetail.accountNo}
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PartnerBankDetailsInfo;
