import React, { useState } from "react";
import { Router } from "react-router-dom";
import { Button, Container, Label } from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import SideBar from "./components/sidebar/SideBar";
import Content from "./components/content/Content";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Loader from "./common/Loader";
// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { user, isLoading, error, logout, loginWithRedirect } = useAuth0();
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);
  const { isAuthenticated } = useAuth0();

  const onLoginAgain = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (error) {
    return (
      <div className="access-denied">
        <h2>
          <strong>Oops...</strong>
        </h2>
        <Label>
          <h5>
            {
              "Somthing went worng, please retry the again. If the issue persists, kindly contact our support team for further assistance."
            }
          </h5>
        </Label>
        <Label>{error.message}</Label>
        <Button color="success" onClick={onLoginAgain}>
          Login
        </Button>
      </div>
    );
  }

  if (user) {
    const roles = user["https://apiuat.healthmoni.com/roles"];
    if (roles) {
      const matched = roles.filter((item) => item === "HMP_USER");
      if (matched.length === 0) {
        return (
          <div className="access-denied">
            <h2>
              <strong>Access denied</strong>
            </h2>
            <Label>
              <h5>You don't have permission to view this page.</h5>
            </Label>
            <Label>
              Kindly contact our support team for further assistance.
            </Label>
            <Button color="success" onClick={onLoginAgain}>
              Login
            </Button>
          </div>
        );
      }
    }
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Router history={history}>
      <div id="root">
        <div className="root-container">
          <div
            className="erd_scroll_detection_container erd_scroll_detection_container_animation_active"
            style={{
              visibility: "hidden",
              display: "inline",
              width: 0,
              height: 0,
              zIndex: -1,
              overflow: "hidden",
              margin: 0,
              padding: 0,
            }}
          >
            <div
              dir="ltr"
              className="erd_scroll_detection_container"
              style={{
                position: "absolute",
                flex: "0 0 auto",
                overflow: "hidden",
                zIndex: -1,
                visibility: "hidden",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
              }}
            >
              <div
                className="erd_scroll_detection_container"
                style={{
                  position: "absolute",
                  flex: "0 0 auto",
                  overflow: "hidden",
                  zIndex: -1,
                  visibility: "hidden",
                  top: "-1px",
                  right: "0px",
                  bottom: "0px",
                  left: "-1px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    flex: "0 0 auto",
                    overflow: "scroll",
                    zIndex: -1,
                    visibility: "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    flex: "0 0 auto",
                    overflow: "scroll",
                    zIndex: -1,
                    visibility: "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "200%",
                      height: "200%",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-bar"></div>
          <div
            className="react-draggable draggable"
            style={{ transform: "translate(220px, 0px)" }}
          ></div>
          <div id="app" className="d-flex flex-column h-100">
            {isAuthenticated && (
              <Container fluid>
                <div className="app_container">
                  <div className="side-box">
                    <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
                  </div>
                  <div className="main-box">
                    <Content
                      toggleSidebar={toggleSidebar}
                      sidebarIsOpen={sidebarIsOpen}
                    />
                  </div>
                </div>
              </Container>
              // <div className="App wrapper">
              //   <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
              //   <Content
              //     toggleSidebar={toggleSidebar}
              //     sidebarIsOpen={sidebarIsOpen}
              //   />
              // </div>
            )}
            {!isAuthenticated && loginWithRedirect()}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
