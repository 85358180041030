import CryptoJS, { HmacSHA256 } from "crypto-js";
import {
  testSendOtp,
  testLinkCustomerAccount,
  testVerifyBeneficiaryName,
  testRegisterBeneficiary,
  testStartPaymentRequest,
  testSendPaymentRequest,
  testUploadDocument,
  testSendPaymentRequestWithOtp,
  testRegisterBeneficiaryWithOtp,
} from "../services/APIServices";

import testDoc from "../data/test_doc.json";

const API_KEY =
  "z3O6uJvFA4Iz-8tSOZeWW2SyBrF3Rw6Y3nQaBP91gdYxrpSjbIZnmeDIJWF9nrYyc9juB_GKYd0";
const SECRET_KEY =
  "1izmaSGzJMvMc9P10INqqsCk7dOv4BHxFAHzDDJgH_ttGDxQF90CAFaYcXQQ";

const generateHmac = (data, secretKey) => {
  return HmacSHA256(data, secretKey).toString(CryptoJS.enc.Base64);
};

export const onTestAPI = async () => {

  const linkAccountPayload = {
    referenceId: "1212swewddsdds",
    accountName: "Paul Lau",
    sortCode: "000000",
    accountNumber: "03631049",
  };

  const linkAccountOtp = await onSendOTPLinkAccount(linkAccountPayload);
  console.log("Link Account Otp: " + linkAccountOtp);
  const linkAccount = await onLinkAccount(linkAccountPayload);
  console.log("Link Account: " + linkAccount);


  const payeePayload = {
    customerId: "ce27db09-d5f7-4c70-aaeb-ef445f9f75ec",
    payeeType: "Individual",
    name: "Ghufran A",
    nickName: "gak",
    otp: "561392",
    person: {
      email: "testpayee@healthmoni.com",
      phone: "+(44)7714326525",
    },
    account: {
      type: "SCAN",
      accountNumber: "12546751",
      branchIdentifier: "000000",
      currency: "GBP",
    },
  };

  const verifyPayeename = await onVerifyPayeeName(payeePayload);
  console.log("Verify Payee Name: " + verifyPayeename);
  const addPayeeOtp = await onSendOTPAddPayee(payeePayload);
  console.log("Add Payee Otp: " + addPayeeOtp);
  const addPayeeWithOtp = await onAddPayeeWithOtp(payeePayload);
  console.log("Add Payee With Otp: " + addPayeeWithOtp);
  const addPayeeWithoutOtp = await onAddPayee(payeePayload);
  console.log("Add Payee Without Otp: " + addPayeeWithoutOtp);

  const prPayload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
  };

  const startPaymentRequest = await onStartPaymentRequest(prPayload);
  console.log("Start Payment Request: " + startPaymentRequest);
  const otpPaymentRequest = await onSendOTPPaymentRequst();
  console.log("Payment Request Otp: " + otpPaymentRequest);
  const sendPaymentRequestWithOtp = await onSendPaymentRequestWithOtp();
  console.log("send Payment Request With Otp: " + sendPaymentRequestWithOtp);
  const sendPaymentRequestWithoutOtp = await onSendPaymentRequest();
  console.log(
    "send Payment Request Without Otp: " + sendPaymentRequestWithoutOtp
  );
  const documentUpload = await onUploadDocument();
  console.log("Uplaod Document: " + documentUpload);
};

// export const onSendOTPLinkAccount = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     otpType: "LINK_ACCOUNT",
//     data: strpayload,
//   };
//   return await testSendOtp(dataObject, API_KEY, hmac);
// };

// export const onSendOTPAddPayee = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     otpType: "ADD_PAYEE",
//     data: strpayload,
//   };
//   return await testSendOtp(dataObject, API_KEY, hmac);
// };

// export const onAddPayee = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testRegisterBeneficiary(dataObject, API_KEY, hmac);
// };

// export const onAddPayeeWithOtp = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testRegisterBeneficiaryWithOtp(dataObject, API_KEY, hmac);
// };

// export const onVerifyPayeeName = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testVerifyBeneficiaryName(dataObject, API_KEY, hmac);
// };

// export const onLinkAccount = async (data) => {
//   const payload = {
//     ...data,
//     otp: "561392",
//   };
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testLinkCustomerAccount(dataObject, API_KEY, hmac);
// };

// export const onSendOTPPaymentRequst = async () => {
//   const payload = {
//     customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
//     paymentId: "5f236975-88d0-40a7-b29c-3812b6b2d44f",
//     accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
//     payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
//     currency: "GBP",
//     amount: 1.0,
//     paymentCategory: 4,
//     referenceMessage: "Making Food on Nov 12th",
//     status: "3",
//     requestDateTime: "2024-05-30 20:39:51",
//   };
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     otpType: "PAYMENT_REQUEST",
//     data: strpayload,
//   };
//   return await testSendOtp(dataObject, API_KEY, hmac);
// };

// export const onStartPaymentRequest = async (payload) => {
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return testStartPaymentRequest(dataObject, API_KEY, hmac);
// };

// export const onSendPaymentRequestWithOtp = async () => {
//   const payload = {
//     customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
//     paymentId: "5f236975-88d0-40a7-b29c-3812b6b2d44f",
//     accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
//     payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
//     currency: "GBP",
//     amount: 1.0,
//     referenceMessage: "Making Food on Nov 12th",
//     categoryCode: 4,
//     status: "3",
//     requestDateTime: "2024-05-30 20:39:51",
//     otp: "561392",
//   };
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testSendPaymentRequestWithOtp(dataObject, API_KEY, hmac);
// };

// export const onSendPaymentRequest = async () => {
//   const payload = {
//     customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
//     paymentId: "34dc2005-551b-42a9-8d70-1325ca6f0290",
//     payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
//     accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
//     currency: "GBP",
//     amount: 1.0,
//     referenceMessage: "Making Food on Nov 12th",
//     categoryCode: 4,
//     status: "3",
//     requestDateTime: "2024-05-30 20:39:51",
//   };
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testSendPaymentRequest(dataObject, API_KEY, hmac);
// };

// export const onUploadDocument = async () => {
//   const base64Content = testDoc.content;
//   const payload = {
//     customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
//     payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
//     ownerId: "5f236975-88d0-40a7-b29c-3812b6b2d44f", //paymentId,
//     documentType: "PaymentReceipt",
//     documentName: "PaymentReceipt",
//     path: "",
//     content: base64Content,
//     tags: {
//       name: "reference",
//       app: "HealthMoni",
//     },
//   };
//   const strpayload = JSON.stringify(payload);
//   const hmac = generateHmac(strpayload, SECRET_KEY);
//   const dataObject = {
//     data: strpayload,
//   };
//   return await testUploadDocument(dataObject, API_KEY, hmac);
// };

//===========================================================================================//

export const onSendOTPLinkAccount = async () => {
  const payload = {
    referenceId: "1212swewddsdds",
    accountName: "Travis Jerome",
    sortCode: "000000",
    accountNumber: "02825463",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    otpType: "LINK_ACCOUNT",
    data: strpayload,
  };
  testSendOtp(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onLinkAccount = async () => {
  const payload = {
    referenceId: "1212swewddsdds",
    accountName: "Travis Jerome",
    sortCode: "000000",
    accountNumber: "02825463",
    otp: "561392",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  console.log(dataObject)
  testLinkCustomerAccount(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onSendOTPAddPayee = async () => {
  const payload = {
    customerId: "1a16ade6-b987-4c01-97f3-0327bedffec4",
    payeeType: "Individual",
    name: "GH Khan",
    nickName: "gak",
    person: {
      email: "ghufran@healthmoni.com",
      phone: "+(44)7714326525",
    },
    account: {
      type: "SCAN",
      accountNumber: "12546755",
      branchIdentifier: "000000",
      currency: "GBP",
    },
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    otpType: "ADD_PAYEE",
    data: strpayload,
  };
  testSendOtp(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onAddPayee = async () => {
  const payload = {
    customerId: "1a16ade6-b987-4c01-97f3-0327bedffec4",
    payeeType: "Individual",
    name: "Ghufran A",
    nickName: "gak",
    person: {
      email: "ghufran@healthmoni.com",
      phone: "+(44)7714326525",
    },
    account: {
      type: "SCAN",
      accountNumber: "12546752",
      branchIdentifier: "000000",
      currency: "GBP",
    },
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testRegisterBeneficiary(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onAddPayeeWithOtp = async () => {
  const payload = {
    customerId: "1a16ade6-b987-4c01-97f3-0327bedffec4",
    payeeType: "Individual",
    name: "GA Khan",
    nickName: "gak",
    otp: "561392",
    person: {
      email: "ghufran@healthmoni.com",
      phone: "+(44)7714326525",
    },
    account: {
      type: "SCAN",
      accountNumber: "12546755",
      branchIdentifier: "000000",
      currency: "GBP",
    },
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testRegisterBeneficiaryWithOtp(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onVerifyPayeeName = async () => {
  const payload = {
    customerId: "ce27db09-d5f7-4c70-aaeb-ef445f9f75ec",
    payeeType: "Individual",
    name: "Ghufran A",
    nickName: "gak",
    otp: "561392",
    person: {
      email: "testpayee@healthmoni.com",
      phone: "+(44)7714326525",
    },
    account: {
      type: "SCAN",
      accountNumber: "12546751",
      branchIdentifier: "000000",
      currency: "GBP",
    },
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testVerifyBeneficiaryName(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onStartPaymentRequest = async () => {
  const payload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testStartPaymentRequest(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onSendPaymentRequest = async () => {
  const payload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    paymentId: "4065af58-24d6-4478-842c-ce35553eac8d",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
    accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
    currency: "GBP",
    amount: 1.0,
    referenceMessage: "Making Food on Nov 12th",
    categoryCode: 4,
    status: "3",
    requestDateTime: "2024-05-30 20:39:51",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testSendPaymentRequest(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};

export const onSendOTPPaymentRequst = async () => {
  const payload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    paymentId: "712f41d1-045c-4e71-aa33-6ded2ab27fde",
    accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
    currency: "GBP",
    amount: 1.0,
    paymentCategory: 4,
    referenceMessage: "Making Food on Nov 12th",
    status: "3",
    requestDateTime: "2024-05-30 20:39:51",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    otpType: "PAYMENT_REQUEST",
    data: strpayload,
  };
  testSendOtp(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};



export const onSendPaymentRequestWithOtp = async () => {
  const payload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    paymentId: "712f41d1-045c-4e71-aa33-6ded2ab27fde",
    accountId: "05d44c80-236c-4213-96d1-c4dd81cc2725",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
    currency: "GBP",
    amount: 1.0,
    referenceMessage: "Making Food on Nov 12th",
    categoryCode: 4,
    status: "3",
    requestDateTime: "2024-05-30 20:39:51",
    otp: "561392",
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testSendPaymentRequestWithOtp(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};



export const onUploadDocument = async () => {
  const base64Content = testDoc.content;
  const payload = {
    customerId: "9a609029-7296-4333-a6f2-42fe8264ce7b",
    payeeId: "c2c781bd19c2499c8a9f6385ed66d7c9",
    ownerId: "712f41d1-045c-4e71-aa33-6ded2ab27fde", //paymentId,
    documentType: "PaymentReceipt",
    documentName: "PaymentReceipt",
    path: "",
    content: base64Content,
    tags: {
      name: "reference",
      app: "HealthMoni",
    },
  };
  const strpayload = JSON.stringify(payload);
  const hmac = generateHmac(strpayload, SECRET_KEY);
  const dataObject = {
    data: strpayload,
  };
  testUploadDocument(dataObject, API_KEY, hmac)
    .then((response) => {
      console.log(response);
    })
    .catch((err) => console.log(err));
};
